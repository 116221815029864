<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"></Load> -->
		<div v-if="!isLoading && infoSectionError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<main v-if="!isLoading && infoSection && infoSection.locale">
			<h1 :style="headerCSSVars">{{ infoSection.locale.title }}</h1>
			<!-- <span class="info" v-html="infoSection.locale.content"/> -->
			<p v-if="!isNetworkOnline"><img :src="getImageUrl(indexdbImage.mainImageUrl)" style="width: 290px; height: 197px; margin-left: 36px;  margin-top:85px"></p>
			<span class="info" v-html="applyFontStyle(infoSection.locale.content)" />
		</main>
		
		<footer>
			<button class="button-white" @click="goBackWithPosition()">
				<i class="mm-icon mm-icon-arrow-back big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
// import Load from '../../../components/Load.vue';
import idb from "../../../api/base/idb";

export default {
	name: "InfoSection",
	
	components: {
		// Load
		Loading,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idInfoSection: this.$route.params.idInfoSection || "0",
			keyboardCustomization: null,
			indexdbImage: ''
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			infoSection: state => state.infoSection.infoSection,
			infoSectionError: state => state.infoSection.infoSectionError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('explore_museum');
			if(this.infoSection?.locale?.title) {
				locationName = this.infoSection.locale.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		headerCSSVars() {
			return {
				'background-color': (this.keyboardCustomization?.keysColor) ? this.keyboardCustomization?.keysColor : 'white',
				 'color': (this.keyboardCustomization?.textColor) ? this.keyboardCustomization?.textColor : 'black'
			};
		},	
	},
	created() {
		debug.log("[InfoSection] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.findInfoSection(this.idInfoSection);
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
					this.getDataImage(this.infoSection.idInfoSection);
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('infoSection', ['findInfoSection']),
		async getDataImage(idInfoSection){
			const indexDbResult =  await idb.getFromDB(parseInt(idInfoSection),'infoSections');
			this.indexdbImage = indexDbResult;
			return indexDbResult;
	},

		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goBackWithPosition() {
			setFullScreen();
			this.$router.push({ name: 'InfoSectionList', params: { idMuseum: this.encrypted }, hash: '#'+this.idInfoSection });
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		 applyFontStyle(html) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(html, 'text/html');
			const elementsWithFonts = doc.querySelectorAll('[style*="font-family"]');
			elementsWithFonts.forEach(element => {
				const fontStyle = element.style.fontFamily;
				element.setAttribute('style', `font-family: ${fontStyle} !important;`);
      });

			if (!this.isNetworkOnline){
			let imgTag = doc.querySelector('img[src*="https://services.museummate.net/resource/download/"]');
			imgTag.style.display = 'none';

			}

      return doc.body.innerHTML;
    },
	
	getImageUrl(url) {
      if (url instanceof Blob){
        return URL.createObjectURL(url);
      }
      return url;
    },

	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	height: 100%;
	background-color: white;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	main {
		height: 100%;
		overflow-y: auto;
		
		h1 {
			font-weight: normal;
			border-bottom: 1px solid $gray-lightest;
			padding: 20px;
			width: 100%;
			font-size: 22px;
			position: fixed;
			top: 0;
			background-color: white;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.info {
			display: block;
			padding: 70px 30px 110px 30px;
			
			& /deep/ h2 {
				font-family: Montserrat, sans-serif;
				font-size: 18px;
				font-weight: bolder;
			}
			
			& /deep/ p, & /deep/ ul {
				font-family: Montserrat, sans-serif;
				margin-block-start: 1em;
				margin-block-end: 1em;
			}
			
			& /deep/ ul {
				padding-left: 40px;
			}
			
			& /deep/ strong {
				font-family: Montserrat, sans-serif;
			}
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
