<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div v-if="museum" class="container">
			<img class="bg-image" src="@/assets/guide_bg.jpg" alt="">
		</div>
		
		<main v-if="!isLoading && museum && !searchingQR">
			<h1>{{ sideMenu && sideMenu.locale ? sideMenu.locale.guideText : $t('connect_with_guide') }}</h1>
			
			<div class="elements">
				<p class="text">{{ $t('guide_code_explication') }}</p>
				<transition name="appear">
					<p v-show="errorCode" class="error">{{ $t(errorCode) }}</p>
				</transition>
				<div class="input">
					<input ref="input" v-model="guideCode" type="text" :placeholder="$t('insert_code')" @keyup.enter="enterCode()">
					<button class="button-qr" @click="searchingQR = true">
						<i class="mm-icon mm-icon-qr-scanner"/>
					</button>
				</div>
			</div>
		</main>
		
		<main v-if="!isLoading && museum && searchingQR">
			<transition name="appear">
				<p v-show="errorCode" class="error-camera">{{ $t(errorCode) }}</p>
			</transition>
			<qrcode-stream @decode="onDecode" @init="onInit" />
			<lottie
				class="qr-overlay"
				:options="qrAnimationOptions"
			/>
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button v-if="!searchingQR" class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-close big"/>
				</button>
				<button v-if="!searchingQR" class="button-blue" @click="enterCode()">
					<i class="mm-icon mm-icon-arrow-right white big"/>
				</button>
				<button v-if="searchingQR" class="button-white" @click="searchingQR = false">
					<i class="mm-icon mm-icon-close big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import { QrcodeStream } from 'vue-qrcode-reader';
const Lottie = () => import("vue-lottie");
import * as animationData from '@/assets/animations/camera.json';
import {setFullScreen} from "@/store/helpers";
// import Load from '../../components/Load.vue';

export default {
	name: "GuidedVisit",
	
	components: {
		Loading,
		// Load,
		QrcodeStream,
		Lottie
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			errorCode: null,
			searchingQR: false,
			guideCode: "",
			qrAnimationOptions: {
				animationData: animationData.default,
				animationSpeed: 1
			},
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			savingResource: state => state.museum.savingResource,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
			sideMenu: state => state.museum.sideMenu,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('connect_with_guide');
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created () {
		debug.log("[GuidedVisit] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		enterCode() {
			setFullScreen();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			
			if (this.guideCode) {
				
				//
				// TODO
				//
				this.errorCode = 'wrong_code';
				//
				// TODO
				//
				
			} else {
				this.errorCode = 'empty_code';
			}
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		},
		
		onDecode (result) {
			this.guideCode = result;
			this.searchingQR = false;
		},
		
		async onInit (promise) {
			this.errorCode = null;
			try {
				await promise;
			} catch (error) {
				this.errorCode = 'qr_error_' + error.name;
			}
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: calc(100% - 90px);
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: calc(100% - 90px);
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.qr-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
		}
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 35%;
			padding-top: 75px;
			
			.logo-image {
				width: 70%;
				height: auto;
			}
		}
		
		h1 {
			font-weight: normal;
			border-bottom: 1px solid $gray-lightest;
			padding: 20px;
			width: 100%;
			font-size: 22px;
			position: fixed;
			top: 0;
			background-color: white;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.appear-leave-active,
		.appear-enter-active {
			transition: 1s;
		}
		.appear-enter {
			opacity: 0;
			transform: translate(0, -100%);
		}
		.appear-leave-to {
			opacity: 0;
			transform: translate(0, 100%);
		}
		
		.error {
			margin: -30px 20px 20px;
			padding: 10px;
			background-color: $red;
			color: white;
			border-radius: 5px;
		}
		
		.error-camera {
			position: fixed;
			margin: 20px;
			padding: 10px;
			background-color: $red;
			color: white;
			border-radius: 5px;
		}
		
		.elements {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			.text {
				margin: 50px 0;
				text-align: center;
				color: white;
				padding: 0 20px;
			}
			
			.input {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 50px;
				height: 60px;
				
				input {
					height: 60px;
					padding: 20px;
					border: none;
					background-color: rgba(255, 255, 255, 0.4);
				}
				
				.button-qr {
					background-color: white;
					height: 100%;
					width: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					
					i {
						font-size: 40px;
						margin-bottom: -8px;
					}
				}
			}
			
			.privacy-policy {
				color: white;
				padding: 15px;
				
				input {
					margin-right: 10px;
					border: none;
					background-color: white;
					color: white;
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
}
</style>
