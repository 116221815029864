<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>
		
		<nav v-if="!isLoading">
			<h1>{{ sideMenu && sideMenu.locale ? sideMenu.locale.inAMinuteText : $t('discover_in_a_minute') }}</h1>
			<div v-if="museum.menuAvailable" class="menu" @click="showMenu = true; setFullScreen()">
				<img class="menu-icon" src="@/assets/icons/stayrelax_black.png" alt="">
			</div>
		</nav>
		
		<div v-if="!isLoading && museum" class="container">
			<img class="bg-image" :src="museum.homeImageUrl" alt="">
		</div>
		
		<div v-if="!isLoading && oneMinutesError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<main v-else>
			<div class="one-minutes-odd">
				<article v-for="oneMinute in oneMinutesInRows[0]" :id="oneMinute.idOneMinute"
				         :key="oneMinute.idOneMinute"
				         :style="{ '--bg-image': 'url('+oneMinute.mainImageUrl+')' }"
				         @click="goToOneMinute(oneMinute)">
					<div class="one-minute">
						<h2>{{ oneMinute.locale.title }}</h2>
						<p>{{ $t('one_minute') }}</p>
					</div>
				</article>
			</div>
			<div class="one-minutes-even">
				<article v-for="oneMinute in oneMinutesInRows[1]" :id="oneMinute.idOneMinute"
				         :key="oneMinute.idOneMinute"
				         :style="{ '--bg-image': 'url('+oneMinute.mainImageUrl+')' }"
				         @click="goToOneMinute(oneMinute)">
					<div class="one-minute">
						<h2>{{ oneMinute.locale.title }}</h2>
						<p>{{ $t('one_minute') }}</p>
					</div>
				</article>
			</div>
			<div class="one-minutes-odd">
				<article v-for="oneMinute in oneMinutesInRows[2]" :id="oneMinute.idOneMinute"
				         :key="oneMinute.idOneMinute"
				         :style="{ '--bg-image': 'url('+oneMinute.mainImageUrl+')' }"
				         @click="goToOneMinute(oneMinute)">
					<div class="one-minute">
						<h2>{{ oneMinute.locale.title }}</h2>
						<p>{{ $t('one_minute') }}</p>
					</div>
				</article>
			</div>
		</main>
		
		<footer>
			<button class="button-white" @click="goToOptions()">
				<i class="mm-icon mm-icon-arrow-back big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import SideMenu from "@/components/SideMenu";
import {setFullScreen} from "@/store/helpers";
// import Load from '../../../components/Load.vue';

export default {
	name: "OneMinuteList",
	
	components: {
		SideMenu,
		// Load,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			oneMinutes: state => state.oneMinute.oneMinutes,
			oneMinutesError: state => state.oneMinute.oneMinutesError,
			sideMenu: state => state.museum.sideMenu,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('discover_in_a_minute');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		oneMinutesInRows() {
			let row1 = [];
			let row2 = [];
			let row3 = [];
			for(let index in this.oneMinutes) {
				let oneMinute = this.oneMinutes[index];
				if((parseInt(index) % 3) === 0) {
					row1.push(oneMinute);
				} else if((parseInt(index) % 3) === 1) {
					row2.push(oneMinute);
				} else {
					row3.push(oneMinute);
				}
			}
			
			return [row1, row2, row3];
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[OneMinuteList] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getOneMinutes();
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('oneMinute', ['getOneMinutes']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goToOneMinute(oneMinute) {
			setFullScreen();
			this.$router.push({ name: 'OneMinute', params: { idMuseum: this.encrypted, idOneMinute: oneMinute.idOneMinute } });
		},
		
		goToOptions() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	nav {
		position: fixed;
		top: 0;
		height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		border-bottom: 1px solid #cfcfd0;
		
		h1 {
			font-weight: normal;
			font-size: 22px;
			padding: 0 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			
			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	main {
		padding-top: 70px;
		padding-left: 10px;
		width: 100%;
		height: 100%;
		overflow-x: scroll;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		
		.one-minutes-odd {
			margin-left: 40%;
		}
		
		.one-minutes-odd, .one-minutes-even {
			display: flex;
			
			article {
				border-radius: 100%;
				width: 250px;
				height: 250px;
				min-width: 250px;
				min-height: 250px;
				margin-top: -10px;
				margin-right: 20px;
				background-image: var(--bg-image);
				background-color: black;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				
				.one-minute {
					padding: 70px 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					
					h2 {
						text-align: center;
						color: white;
						font-size: 28px;
						font-weight: normal;
						margin-bottom: 10px;
						background: linear-gradient(transparent 20%, rgba(0, 0, 0, 0.4));
						padding: 0 5px;
					}
					
					p {
						text-transform: uppercase;
						color: var(--main-buttons-text-color);
						padding: 10px 10px;
						background-color: var(--main-buttons-color);
						border-radius: 50px;
					}
				}
			}
		}
	}
	
	footer {
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.button-white {
			border: none;
			background-color: white;
			height: 100%;
			color: black;
			
			img {
				width: 15px;
				height: auto;
			}
		}
	}
	
}


</style>
