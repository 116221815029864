<template>
  <div class="content">
    <vue-headful :title="pageTitle" />

    <!-- <Load v-if="isLoading"></Load> -->
     <loading :active.sync="isLoading" :is-full-page="true" /> 

    <side-menu
      v-if="!isLoading"
      v-show="showMenu"
      :id-museum="encrypted"
      @close-menu="showMenu = false"
    />

    <nav v-if="!isLoading" :style="headerCSSVars" >
      <h1>
        {{
          sideMenu && sideMenu.locale && sideMenu.locale.exploreMuseumText
            ? sideMenu.locale.exploreMuseumText
            : $t("explore_museum")
        }}
      </h1>
      <div
        v-if="museum.menuAvailable"
        class="menu"
        @click="
          showMenu = true;
          setFullScreen();
        "
      >
        <img
          class="menu-icon"
          src="@/assets/icons/stayrelax_black.png"
          alt=""
        />
      </div>
    </nav>

    <div v-if="!isLoading && infoSectionsError" class="error-code">
      {{ $t("no_resources") }}
    </div>

    <main v-else-if="!isLoading" class="grid">
      <article
        v-for="infoSection in infoSections"
        :id="infoSection.idInfoSection"
        :key="infoSection.idInfoSection"
        @click="goToInfoSection(infoSection)"
      >
        <img class="info-image" :src="imageUrl(infoSection.mainImageUrl)" alt="" />
        <div class="info-button">
          <h2>{{ infoSection.locale.title }}</h2>
          <i class="mm-icon mm-icon-arrow-right gray" />
        </div>
      </article>
    </main>

    <footer>
      <button class="button-white" @click="goToOptions()">
        <i class="mm-icon mm-icon-arrow-back big" />
      </button>
    </footer>
  </div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SideMenu from "@/components/SideMenu";
import { setFullScreen } from "@/store/helpers";
import { getImageUrl } from "../../../utils/getImageUrl";
// import Load from '../../../components/Load.vue';

export default {
  name: "InfoSectionList",

  components: {
    SideMenu,
    // Load,
    Loading,
  },

  data() {
    return {
      encrypted: this.$route.params.idMuseum,
      idMuseum: "0",
      showMenu: false,
      keyboardCustomization: null,
	  exploreMuseumText: '',
    };
  },

  computed: {
    ...mapState({
      museum: (state) => state.museum.museum,
      isLoading: (state) => state.app.isLoading,
      isNetworkOnline: (state) => state.app.isNetworkOnline,
      infoSections: (state) => state.infoSection.infoSections,
      infoSectionsError: (state) => state.infoSection.infoSectionsError,
	  sideMenu:(state) =>  state.museum.sideMenu,
    }),

    pageTitle() {
      let locationName = this.$i18n.t("explore_museum");
      let museumName = this.museum?.name
        ? this.museum.name
        : this.museum?.locale?.name
        ? this.museum.locale.name
        : "MuseumMate";

      return locationName + " | " + museumName;
    },
    headerCSSVars() {
      return {
        "background-color": this.keyboardCustomization?.keysColor
          ? this.keyboardCustomization?.keysColor
          : "white",
        color: this.keyboardCustomization?.textColor
          ? this.keyboardCustomization?.textColor
          : "black",
      };
    },

  },

  created() {
    debug.log("[InfoSectionList] Creating view");
    this.$store.commit("app/" + mutationTypes.SET_IS_LOADING, true);

    try {
      let decryption = atob(this.encrypted);
      this.idMuseum = decryption.split("-")[0];

      this.loadMuseum(this.idMuseum)
        .then(() => {
          this.getInfoSections();
          if (
            this.museum.keyboardCustomization &&
            this.museum.keyboardCustomization.active
          ) {
            this.keyboardCustomization = this.museum.keyboardCustomization;
          }
		  if (
            this.sideMenu.localizations &&
            this.sideMenu.localizations[0].exploreMuseumText
          ) {
            this.exploreMuseumText = this.museum.localizations[0].exploreMuseumText;
          }
        })
        .finally(() => {
          this.$store.commit("app/" + mutationTypes.SET_IS_LOADING, false);
        });
    } catch (e) {
      if (process.env.VUE_APP_DEBUG_MODE == "true")
        console.error("Error deciphering: ", e);
      this.$store.commit("app/" + mutationTypes.SET_IS_LOADING, false);
    }
  },

  methods: {
    ...mapActions("app", ["loadMuseum"]),
    ...mapActions("infoSection", ["getInfoSections"]),

    setFullScreen(to = null) {
      setFullScreen();
      if (to) {
        this.$router.push(to);
      }
    },

    goToInfoSection(infoSection) {
      setFullScreen();
      this.$router.push({
        name: "InfoSection",
        params: {
          idMuseum: this.encrypted,
          idInfoSection: infoSection.idInfoSection,
        },
      });
    },

    goToOptions() {
      setFullScreen();
      this.$router.push({
        name: "Options",
        params: { idMuseum: this.encrypted },
      });
    },
    imageUrl(imgUrl) {
    return  getImageUrl(imgUrl);
      
    }
  },
  
};
</script>

<style lang="scss" scoped>
@import "../../../theme/colors";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .error-code {
    padding: 80px 20px 100px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
  }

  nav {
    position: fixed;
    top: 0;
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #cfcfd0;

    h1 {
      font-weight: normal;
      font-size: 22px;
      padding: 0 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #cfcfd0;
      padding: 5px 20px;

      .menu-icon {
        width: 40px;
        height: auto;
      }
    }
  }

  .grid {
    padding: 90px 20px 110px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow-y: scroll;

    article {
      width: 100%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      margin-bottom: 20px;

      .info-image {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      .info-button {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          text-transform: uppercase;
          color: $gray-light;
          font-size: 18px;
          font-weight: normal;
          margin-right: 20px;
        }

        i {
          margin-bottom: 0;
        }
      }
    }
  }

  footer {
    height: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: fixed;
    bottom: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    .button-white {
      border: none;
      background-color: white;
      height: 100%;
      color: black;

      img {
        width: 15px;
        height: auto;
      }
    }
  }
}
</style>
