<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div class="container">
			<div v-if="customization && customization.artworkCustomizationEnabled && customization.artworkBackgroundColor" class="bg-image"/>
			<img v-if="(customization && !customization.artworkCustomizationEnabled) || (customization && !customization.artworkBackgroundColor)" class="bg-image" :src="bgImage" alt="">
		</div>
		
		<div v-if="!isLoading && oneMinuteError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<main v-else-if="!isLoading && oneMinute && oneMinute.locale">
			<img class="one-minute" :src="oneMinute.mainImageUrl" alt="">
			
			<h2>{{ oneMinute.locale.title }}</h2>
			
			<audio-player v-if="oneMinute.locale.mainAudioUrl"
			              :file="oneMinute.locale.mainAudioUrl"
			              :text-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"
			              :icons-color="customization && customization.artworkCustomizationEnabled && customization.artworkTextColor ? customization.artworkTextColor : '#FFFFFF'"/>
			
			<p v-if="!oneMinute.locale.mainAudioUrl && oneMinute.locale.mainText">{{ oneMinute.locale.mainText }}</p>
		</main>
		
		<footer :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-white" @click="goBackWithPosition()">
					<i class="mm-icon mm-icon-close big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";
import firebase from "firebase/app";
// import Load from '../../../components/Load.vue';


export default {
	name: "OneMinute",
	
	components: {
		// Load,
		Loading,
		AudioPlayer
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			idOneMinute: this.$route.params.idOneMinute || "0",
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			oneMinute: state => state.oneMinute.oneMinute,
			oneMinuteError: state => state.oneMinute.oneMinuteError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('discover_in_a_minute');
			if(this.oneMinute?.locale?.title) {
				locationName = this.oneMinute.locale.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		bgImage() {
			let bgImage = null;
			
			if (!this.artworkError && this.artwork && this.artwork.hasOwnProperty('mainImageUrl')) {
				bgImage = this.artwork.mainImageUrl;
			}
			
			if(this.customization?.artworkCustomizationEnabled && this.customization.artworkBackgroundImageUrl) {
				bgImage = this.customization.artworkBackgroundImageUrl;
			}
			
			if (!bgImage) {
				bgImage = '@/assets/home.jpg';
			}
			
			return bgImage;
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
		
		cssVars() {
			return {
				'--artwork-text-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkTextColor : '#8f8f8f',
				'--artwork-background-color': (this.customization?.artworkCustomizationEnabled) ? this.customization.artworkBackgroundColor : '#ffffff',
			};
		}
	},
	
	created() {
		debug.log("[OneMinute] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.findOneMinute(this.idOneMinute);
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(() => {
					if(firebase.app().options.measurementId) {
						debug.log("Logging page_view analytics");
						firebase.analytics().logEvent('page_view', {
							page_location: this.$router.currentRoute.fullPath,
							page_title: this.pageTitle
						});
					} else {
						debug.log("No measurementId found, no analytics sent");
					}
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('oneMinute', ['findOneMinute']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goBackWithPosition() {
			setFullScreen();
			this.$router.push({ name: 'OneMinuteList', params: { idMuseum: this.encrypted }, hash: '#'+this.idOneMinute });
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	width: 100%;
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
			background-color: var(--artwork-background-color);
		}
	}
	
	main {
		padding: 20px 20px 110px 20px;
		color: var(--artwork-text-color);
		height: 100%;
		overflow-y: auto;
		
		.one-minute {
			padding: 20px;
			width: 100%;
			height: auto;
		}
		
		h2 {
			color: var(--artwork-text-color);
			font-weight: normal;
			font-size: 32px;
			margin-bottom: 10px;
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}
</style>
